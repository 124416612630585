body {
    background-color: rgb(243, 243, 243);
    font-size: 1.125em;
}

h2 {
    margin: 0 auto;
    font-size: 2rem;
    font-weight: 600;
}

p {
    line-height: 1.5;
}

.section-title {
    text-align: center;
    margin-bottom: 2rem;
}

.section-container {
    margin: 0 auto;
    background-color: white;
    max-width: 85rem;
    border-radius: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
}

.section-container-alt {
    margin: 0 auto;
    background-color: rgb(243, 243, 243);
    max-width: 85rem;
    margin-bottom: 2rem;
}

.underline {
    margin: 0 auto;
    margin-top: 1rem;
    border-top: 2px solid;
    width: 10%;
}

ul li {
    list-style-type: circle;
}