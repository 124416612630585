.education-container {
    background-color: white;
    position: relative;
}

.education-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/chapel.png');
    background-size: cover;
    background-position: center center;
    opacity: 0%;
    z-index: -1;
    transition-duration: 1s;
    border-radius: 1rem;
}

.education-container:hover {
    background-color: transparent;
}

.education-container:hover .education-background {
    opacity: 30%;
}

.subtext {
    color: rgb(100, 100, 100);
    font-size: 0.9rem;
}

.education-container:hover .subtext {
    color: black;
}

.school-container {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 97%;
}

.duke-name {
    display: block;
    margin: 4rem auto;
    margin-bottom: 0;
    width: 15rem;
}

.degree-name {
    font-weight: 600;
    text-align: center;
    margin: 0 auto;

}

.date-text {
    text-align: center;
    margin-bottom: 1rem;
}

.gpa-text {
    text-align: center;
}

.courses-container {
    max-width: 80%;
    margin: 0 auto;
    padding-bottom: 2rem;
}

.courses-list-title {
    margin: 0 auto;
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-self: 'center';
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    transition-duration: 500ms;
}

.courses-list {
    column-count: auto;
    column-width: 20rem;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
}

.courses-list-title:hover {
    color: rgb(20, 150, 200);
}