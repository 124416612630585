.navbar {
    background-color: white;
    position: sticky;
    top: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.navbar-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90rem;
    margin: 0 auto;
}

.logo {
    margin: 0 4rem;
    width: 5rem
}

.nav-links {
    margin-right: calc(100/16 *1rem);
    display: flex;
    align-items: center;
}

.navbar li {
    list-style: none;
    display: inline-block;
}

.navbar li a {
    text-decoration: none;
    color: black;
    font-size: calc(20/16 *1rem);
    padding: calc(8/16 *1rem) calc(30/16 *1rem);
    transition-duration: 500ms;
    cursor: pointer;
}

.navbar li a:hover {
    color: rgb(20, 150, 200);
}

.menu-icon {
    display: none;
}

@media (max-width: 50rem) {
    .menu-icon {
        display: block;
        cursor: pointer;
        margin-right: 2rem;
        font-size: 1.5rem;
        color: gray;
    }

    .nav-links {
        margin-right: 0;
        position: absolute;
        right: 0;
        top: 4.2rem;
        width: 0;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

    }

    .nav-links.active {
        width: 12rem;
    }

    .nav-links li {
        margin-top: 1.5rem;
        margin-right: unset;
    }

    .nav-links li a {
        font-size: 1.5rem;
        display: none;
    }

    .nav-links.active li a {
        display: block;
    }
}

@media (max-width: 50rem) and (orientation: landscape) {
    .nav-links li {
        margin-top: 1rem;
        margin-right: unset;
    }
}