.home {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-color: white;
    margin-bottom: 2rem;
}

.home-container {
    margin: 4rem auto;
    max-width: 80rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    overflow: hidden;
}

.portrait {
    max-width: 30%;
    min-width: 15rem;
    text-align: center;
    flex: 1;
    margin: 2rem;
}

.intro-content {
    max-width: 40%;
    min-width: 20rem;
    flex: 1;
}

.name-text {
    text-align: center;
    font-size: 2.5rem;
    margin: 0;

}

.social-icons {
    text-align: center;
    padding: 0;
}

.social-icons li {
    list-style: none;
    display: inline-block;
}

.social-icons li a:hover {
    color: rgb(20, 150, 200);
}

.social-icons li a {
    color: black;
    padding: 0.5rem;
    transition-duration: 500ms;
    padding-bottom: 0;
}

.social-icons li p {
    font-size: 0.7rem;
    margin-top: 0;
}

.Github-text {
    color: white;
    transition-duration: 500ms;
}

.LinkedIn-text {
    color: white;
    transition-duration: 500ms;
}

.Email-text {
    color: white;
    transition-duration: 500ms;
}

.Resume-text {
    color: white;
    transition-duration: 500ms;
}

.Github-icon:hover+.Github-text {
    color: rgb(20, 150, 200);
}

.LinkedIn-icon:hover+.LinkedIn-text {
    color: rgb(20, 150, 200);
}

.Email-icon:hover+.Email-text {
    color: rgb(20, 150, 200);
}

.Resume-icon:hover+.Resume-text {
    color: rgb(20, 150, 200);
}


.blurb {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    font-size: 1.5rem;
}

@media (max-width: 50rem) {
    .blurb {
        font-size: 1.125rem;
    }
}

.email-text {
    text-align: center;
}