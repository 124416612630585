.skill-section-container {
    min-height: 20rem;
}

.skill-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
}

.skill-button {
    background-color: white;
    color: #999;
    font-size: 1.125rem;
    border: 0;
    margin: 1rem;
    transition-duration: 500ms;
    cursor: pointer;
    border-radius: 1rem;
    padding: 0.5rem;
}

.skill-button:hover {
    color: rgb(20, 150, 200);
}

.skill-button.pressed {
    background-color: rgb(219, 219, 219);
    color: black;
}

.skill-icon-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 1rem;
}

@media (max-width: 50rem) {
    .skill-icon-container {
        width: 90%;
    }
}

.skill-card {
    display: none;
    border-radius: 1rem;
    align-items: center;
    margin: 1rem 1rem 0 0;
    background-color: rgb(239, 239, 239);
    padding: 0.5rem 1rem;
}

.skill-text {
    margin: 0 0 0 0.2rem;
}

.all.java,
.all.python,
.all.c,
.all.sql,
.all.javascript,
.all.go,
.all.git,
.all.restful-apis,
.all.mongodb,
.all.spring-boot,
.all.react-js,
.all.react-native,
.all.swift,
.all.express-js,
.all.node-js,
.all.html,
.all.css,
.all.pandas,
.all.r,
.all.numpy,
.all.aws,
.languages.java,
.languages.python,
.languages.c,
.languages.sql,
.languages.javascript,
.languages.go,
.languages.swift,
.languages.html,
.languages.css,
.languages.r,
.libraries-frameworks.git,
.libraries-frameworks.restful-apis,
.libraries-frameworks.spring-boot,
.libraries-frameworks.react-js,
.libraries-frameworks.react-native,
.libraries-frameworks.express-js,
.libraries-frameworks.node-js,
.libraries-frameworks.pandas,
.libraries-frameworks.numpy,
.backend.java,
.backend.python,
.backend.c,
.backend.sql,
.backend.javascript,
.backend.go,
.backend.mongodb,
.backend.spring-boot,
.backend.express-js,
.backend.node-js,
.frontend.javascript,
.frontend.react-js,
.frontend.react-native,
.frontend.html,
.frontend.css,
.data-science.python,
.data-science.pandas,
.data-science.numpy,
.data-science.r,
.mobile.swift,
.mobile.react-native,
.certificates.aws {
    display: flex;
}