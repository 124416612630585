.project-card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    margin: 2rem auto;
    width: 80%;
}

.img-link {
    width: 25rem;
    height: 15rem;
    object-fit: cover;
    margin-right: 1rem;
}

.project-detail-container {
    width: 60%;
}

@media (max-width: 50rem) {
    .img-link {
        width: 20rem;
        height: 12rem;
        object-fit: cover;
        margin-right: 0rem;
    }

    .project-detail-container {
        width: 90%;
    }
}

.project-name {
    font-weight: 600;
    text-align: center;
}

.links-list {
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.links-list li {
    list-style: none;
    display: inline-block;
}

.links-list li a {
    color: black;
    padding: 0.5rem;
    transition-duration: 500ms;
    padding-bottom: 0;
}

.links-list li a:hover {
    color: rgb(20, 150, 200);
}

.description-text {
    text-align: center;
    margin: 0 auto;
    margin-top: 0.5rem;
}

.skill-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5rem;
    padding: 0;
}

.skill-list li {
    font-size: 0.9rem;
    background-color: #eee;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin: 0.5rem 1rem 0 0.5rem;
}

.skill-list li:first-child {
    list-style: none;
}

.skill-list li::marker {
    font-size: 1rem;
}