.experience-card-container {
    min-width: 20rem;
    width: 40%;
    background: white;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    margin: 1.5rem;
    margin-bottom: 0;
}

@media (max-width: 50rem) {
    .experience-card-container {
        margin-top: 1rem;
        width: 80%;
    }
}

.logo {
    height: 3rem;
    margin: 1.2rem;
}

.role-name {
    font-weight: bold;
    color: #333;
    margin: 0 1.1rem 0.5rem 1.1rem;
}

.date-text {
    color: rgb(100, 100, 100);
    font-size: 0.9rem;
    margin: 0 1.1rem 1.1rem;
}

.description-list {
    list-style: none;
    padding: 0;
    margin: 0 1.1rem 1.1rem;
}

.description-list li {
    margin-bottom: 0.6rem;
}